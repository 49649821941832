<template>
  <VanPopup
    style="
      box-sizing: border-box;
      width: 93%;
      max-width: 350px;
      padding: 20px;
      background: #060621;
    "
    round
    v-model="show"
    @click-overlay="closePop"
  >
    <DaoRule type="1"></DaoRule>
    <div class="window-wrap">
      <div class="logo">
        <img :src="dataSource.params[0] || defaultImg" alt="" />
        <div class="name" v-if="lang === 'ZH'">
          {{ dataSource.params[1] }}
        </div>
        <div class="name" v-else>{{ dataSource.params[2] }}</div>
      </div>
      <div class="contract">
        <div class="name">{{ $t('my.addressDes') }}</div>
        <div class="address" translate="no">
          {{ $gbUtils.formatAddress(dataSource.token, '...', 10) }}
        </div>
      </div>
      <div class="content">
        <div class="key-value">
          <div class="key">{{ $t('my.tokenName') }}</div>
          <div class="value" translate="no">{{ tokenName }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('my.totalSupply') }}</div>
          <div class="value" translate="no">{{ totalSupply }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('my.needStake') }}</div>
          <div class="value" translate="no">{{ totalSupply / 1000000 }}</div>
        </div>
        <div class="key-value">
          <div class="key">{{ $t('my.account') }}</div>
          <div class="value" translate="no">
            {{ $gbUtils.formatBalance(tokenBalance) }}
          </div>
        </div>
      </div>
      <div class="button-group">
        <VanButton
          class="button"
          :loading="btnLoading"
          v-if="!authToken"
          @click="approveToken"
          >{{ $t('my.auth') }}</VanButton
        >
        <VanButton
          class="button"
          :loading="btnLoading"
          @click="handleApplyDao"
          v-else
          >{{ $t('my.appBtn') }}</VanButton
        >
      </div>
    </div>
  </VanPopup>
</template>

<script>
import { mapState } from 'vuex'
import contractDao from '@/contracts/contractDao'
import config from '@/config/app.config'
import DaoRule from '../../../components/DaoRule.vue'
import ContractToken from '@/contracts/contractToken'

export default {
  name: '',
  props: ['dataSource'],
  data () {
    return {
      defaultImg: require('../../../assets/images/logo.png'),
      show: true,
      authToken: false,
      btnLoading: true,
      tokenName: '',
      totalSupply: 0,
      tokenBalance: 0
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const resp = await ContractToken.allowance(this.dataSource.token, config.daoAddress)
      // // console.log('allowance', resp)
      if (resp.success) {
        this.authToken = resp.result > 0
      }
      this.btnLoading = false
    },
    // 授权Token
    async approveToken () {
      this.btnLoading = true
      const resp = await ContractToken.approve(this.dataSource.token, config.daoAddress, 100000000000000000) // 授权LP
      this.btnLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.authToken = true
    },
    async handleApplyDao () {
      this.btnLoading = true
      const resp = await contractDao.councliApply(this.dataSource.daoAt)
      this.btnLoading = false
      if (!resp.success) return
      this.$toast('success')
      this.closePop()
    },
    async getTokenInfo () {
      const nameRes = await ContractToken.getSymbol(this.dataSource.token)
      const supplyRes = await ContractToken.totalSupply(this.dataSource.token)
      const balanceRes = await ContractToken.balanceOf(this.dataSource.token)
      if (nameRes.success && supplyRes.success && balanceRes.success) {
        this.tokenName = nameRes.result
        this.totalSupply = supplyRes.result
        this.tokenBalance = balanceRes.result
      }
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  mounted () {
    this.getAllowance()
    this.getTokenInfo()
  },
  components: { DaoRule }
}
</script>

<style scoped lang="scss">
.window-wrap {
  margin-top: 20px;
  height: 487px;
  width: 100%;
  background: $main-bg;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  color: $main-color1;
  .logo {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .name {
      font-weight: 600;
      font-size: 14px;
      margin-top: 15px;
    }
  }
  .contract {
    margin-top: 20px;
    .address {
      margin-top: 10px;
      height: 22px;
      background: $color-blue1;
      box-shadow: $shadow;
      border-radius: 11px;
      line-height: 22px;
      text-align: center;
    }
  }
  .content {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    .key-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      .key {
      }
      .value {
        font-size: 14px;
        color: $base-color;
        font-weight: 600;
        text-align: right;
      }
    }
  }
  .button-group {
    display: flex;
    justify-content: center;
  }
  .button {
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    margin: 0 auto;
    text-align: center;
    line-height: 39px;
    color: #fff;
    width: 70%;
    font-size: 16px;
  }
}
</style>
