<template>
  <div class="dao-item">
    <div class="info-box">
      <img :src="dataSource.params[0] || defaultImg" alt="" />
      <div class="info">
        <div class="name" v-if="lang === 'ZH'">
          {{ dataSource.params[1] }}
        </div>
        <div class="name" v-else>{{ dataSource.params[2] }}</div>
        <div class="member">
          {{ dataSource.memberCount }}{{ $t('my.members') }}
        </div>
      </div>
    </div>
    <div class="operation" @click.stop="handleShowMenu">
      <span>  {{ $t('my.operation')}}</span>
      <img class="icon" src="../../../assets/images/down-arrow.png">
    </div>
    <div class="wrapper" ref="menu" v-if="menuShow" @click.stop>
      <div class="operation" @click="menuShow = false">
        <span>  {{ $t('my.operation')}}</span>
        <img class="icon" src="../../../assets/images/down-arrow.png">
      </div>
      <div
        class="item modify-info"
        @click.stop="handleToModify"
        v-if="isCouncliAt"
      >
        {{ $t('my.modifyInfo') }}
      </div>
      <!-- <div class="item apply" @click.stop="handleApplyShow" v-if="!isCouncliAt">
        {{ $t('my.apply') }}
      </div> -->
      <!-- <div class="item remove" @click.stop="handleRemoveShow(0)" v-else>
        {{ $t('my.remove') }}
      </div> -->
      <div
        class="item quit"
        v-if="!isCouncliAt"
        @click.stop="handleRemoveShow(1)"
      >
        {{ $t('my.quit') }}
      </div>
    </div>
    <ApplyCouncli
      v-if="applyShow"
      @close="handleApplyShow"
      :dataSource="dataSource"
    ></ApplyCouncli>
    <RemoveCouncli
      :dataSource="dataSource"
      v-if="removeShow"
      @close="handleRemoveShow"
      :isRemove="isRemove"
    ></RemoveCouncli>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApplyCouncli from './ApplyCouncli.vue'
import RemoveCouncli from './RemoveCouncli.vue'
import contractDao from '@/contracts/contractDao'
export default {
  name: '',
  props: ['dataSource'],
  data () {
    return {
      defaultImg: require('../../../assets/images/logo.png'),
      menuShow: false,
      removeShow: false,
      applyShow: false,
      isRemove: 0,
      isCouncliAt: false
    }
  },
  methods: {
    handleHideMenu () {
      this.menuShow = false
    },
    handleApplyShow () {
      this.$emit('hide')
      this.applyShow = !this.applyShow
    },
    handleRemoveShow (index) {
      this.$emit('hide')
      this.isRemove = index
      console.log('this.isRemove', this.isRemove)
      this.removeShow = !this.removeShow
    },
    async handleShowMenu () {
      this.$emit('hide')
      await this.getIsCouncliAt()
      this.menuShow = true
    },
    async getIsCouncliAt () {
      this.$emit('changeLoading', true)
      const resp = await contractDao.userCouncli(this.dataSource.daoAt)
      this.$emit('changeLoading', false)
      if (!resp.success) return
      this.isCouncliAt = resp.result
    },
    handleToModify () {
      localStorage.setItem('daoDetail', JSON.stringify(this.dataSource))
      this.$router.push({
        path: '/modifyDao?dao'
      })
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  mounted () {
    // this.getIsCouncliAt()
  },
  created () {
    document.addEventListener('click', this.handleHideMenu)
  },
  destroyed () {
    document.removeEventListener('click', this.handleHideMenu)
  },
  components: { ApplyCouncli, RemoveCouncli }
}
</script>

<style scoped lang="scss">
.dao-item {
  margin-top: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  height: 73px;
  background: $main-bg;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .info-box {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
     object-fit: scale-down;
    }
    .info {
      margin-left: 10px;
      .name {
        color: $color-gray3;
        font-weight: 600;
        font-size: 16px;
      }
      .member {
        margin-top: 5px;
        color: $main-color1;
      }
    }
  }
  /deep/ .van-overlay {
    position: fixed;
    // background-color: transparent;
    left: 0;
    top: 0;
    min-height: 80vh;
  }
  .overlay {
    position: absolute;
  }
  .wrapper {
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 15px;
    width: 121px;
    background: #060621;
    border-radius: 8px;
    color: #F9F9F9;
    overflow: hidden;
    padding: 2px;
    &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
     margin: -1px;
     border-radius: 8px; /*important*/
     background: linear-gradient(to right, #0078FF, #7C40B7);
  }
    .item {
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: inherit;
      &:last-child {
         border-radius: 0 0 8px 8px;
      }
    }
    .operation {
      position: relative;
      box-shadow: none;
      justify-content: center;
      background: inherit;
      border-radius: 8px 8px 0 0;
      color: #F9F9F9;
    }
    .icon {
      transform: rotate(180deg);
      // margin-left: 0;
      position: absolute;
      right: 15px;
      height: auto;
    }
  }
}
.operation {
  padding: 0 20px;
  height: 40px;
  background: #35353b;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: $btn-blue1;
  line-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
     z-index: -1;
     margin: -1px;
     border-radius: inherit; /*important*/
     background: linear-gradient(to right, #0078FF, #7C40B7);
  }
  .icon {
    width: 13px;
    // height: 18px;
    margin-left: 10px;
    color: #0078FF;
  }
}
</style>
