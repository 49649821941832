<template>
  <div>
    <div class="my-header">
      <div class="tab">
        <div
          class="tab-item"
          :class="{ active: currentTab === 0 }"
          @click="handleChangeTab(0)"
        >
          {{ $t('my.myVote') }}
        </div>
        <div
          class="tab-item"
          :class="{ active1: currentTab === 1 }"
          @click="handleChangeTab(1)"
        >
          {{ $t('my.daoAdmin') }}
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="status" v-if="currentTab === 0" @click.stop="handleShowMenu">
        {{ currentMenu.text }}
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#xiangxiajiantou-copy"></use>
        </svg>
      </div>
      <div class="wrapper" ref="menu" v-if="menuShow">
        <div
          class="item"
          v-for="item in menuList"
          :key="item.value"
          @click="handleChangeMenu(item)"
          :class="{ active: item.value === currentMenu.value }"
        >
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data () {
    return {
      currentTab: 0,
      menuShow: false,
      menuList: [
        { value: 0, text: this.$t('my.all') },
        { value: 1, text: this.$t('my.ing') },
        { value: 2, text: this.$t('my.wait') },
        { value: 3, text: this.$t('my.end') }
      ],
      currentMenu: {}
    }
  },
  methods: {
    // 切换tab
    handleChangeTab (index) {
      this.currentTab = index
      this.$emit('changeTab', index)
    },
    // 切换投票分类
    handleChangeMenu (item) {
      this.currentMenu = item
      this.$emit('changeMenu', item.value)
    },
    handleHideMenu () {
      this.menuShow = false
    },
    handleShowMenu () {
      this.menuShow = true
    }
  },
  mounted () {
    this.currentTab = this.value
    this.currentMenu = this.menuList[0]
  },
  created () {
    document.addEventListener('click', this.handleHideMenu)
  },
  destroyed () {
    document.removeEventListener('click', this.handleHideMenu)
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.my-header {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
  background: -webkit-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
  background: -o-linear-gradient(90deg, #0078ff 0%, #7c40b7 100%);
  border-radius: 8px;
  height: 40px;
  padding: 1px;
  .tab {
    display: flex;
    flex: 1;
    height: 38px;
    background-color: $bg-color1;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: $btn-blue1;
    line-height: 40px;
    .tab-item {
      flex: 1;
      text-align: center;
    }
    .active {
      height: 38px;
      background: $linear-bg3;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
      border-radius: 8px 0px 0px 8px;
    }
    .active1 {
      height: 38px;
      background: $linear-bg3;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
      border-radius: 0px 8px 8px 0px;
    }
  }
}
.menu {
  position: relative;
  .status {
    position: relative;
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    background: #37364f;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: $base-color1;
    text-align: center;
    justify-content: center;
    .icon {
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      margin-left: 5px;
    }
  }
  .wrapper {
    position: absolute;
    z-index: 99;
    top: 0;
    width: 100%;
    background: #080521;
    border-radius: 8px;
    color: $base-color1;
    .item {
      height: 40px;
      text-align: center;
      line-height: 40px;
    }
    .active {
      font-weight: 600;
      color: $base-color1;
    }
    .operation {
      display: flex;
      align-items: center;
      position: relative;
      background-color: #fff;
      box-shadow: none;
      justify-content: center;
      line-height: 40px;
      text-align: center;
      .icon {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        line-height: 40px;
      }
    }
    .icon {
      transform: rotate(180deg);
      // margin-left: 0;
      position: absolute;
      right: 15px;
    }
  }
}
</style>
