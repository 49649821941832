<template>
  <div class="proposal-wrap" @click="handleToDetail">
    <div class="item-header">
      <div class="dao-info">
        <img :src="dataSource.logo" alt="" />
        <div class="name">{{ dataSource.name }}</div>
        <div class="time" v-if="timeStatus === 1">
          {{ $t('proposal.left') }}{{ dataSource.endTime }}
        </div>
      </div>
      <div class="time-info">
        <div class="status-wrap" v-if="timeStatus === 1">
          <div class="status">{{ $t('proposal.voting') }}</div>
        </div>
        <div class="status status4" v-else-if="timeStatus === 4">
          {{ $t('proposal.waitVote') }}
        </div>
        <div class="status status2" v-else>{{ $t('proposal.ended') }}</div>
      </div>
    </div>
    <div class="title">
      {{ dataSource.name_desc[0] }}
    </div>
    <div class="content ellipsis-3">
      {{ dataSource.name_desc[1] }}
    </div>
    <div class="bottom">
      <div class="tag-group">
        <div class="tag" :class="`type${type}`">
          {{ list1[type] }}
        </div>
        <div class="tag" :class="`tag${tag}`">
          {{ list2[tag] }}
        </div>
        <div class="tag pink-bg" v-if="isReward">
          {{ voteReward }}
        </div>
      </div>
      <div class="info">
        {{ $gbUtils.formatBalance(dataSource.totalVote / 1e18) }}
        <span>{{ $t('proposal.ticket') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ContractToken from '@/contracts/contractToken'
import { mapActions } from 'vuex'
export default {
  name: '',
  props: ['dataSource'],
  data () {
    return {
      // startTime: '',
      // endTime: '',
      // startTimeArr: '',
      // endTimeArr: '',
      type: 0,
      tag: 0,
      isReward: false,
      timeStatus: 0, // 0未开始 1投票中 2已结束
      tokenName: ''
    }
  },
  computed: {
    list1 () {
      return this.$t('proposal.list1')
    },
    list2 () {
      return this.$t('proposal.list2')
    },
    voteReward () {
      return this.$t('proposal.voteReward')
    }
  },
  methods: {
    ...mapActions(['chanegProposalDetail']),
    handleToDetail () {
      this.chanegProposalDetail(this.dataSource)
      this.$router.push({
        path: `/proposalDetail?status=${this.timeStatus}`
      })
    },
    async getTokenInfo () {
      const nameRes = await ContractToken.getSymbol(this.dataSource.token)
      if (nameRes.success) {
        this.tokenName = nameRes.result
      }
    },
    init () {
      // this.startTimeArr = this.dataSource.uintParams[3]
      // this.endTimeArr = this.dataSource.uintParams[4]
      // this.startTime = this.$gbUtils.showStartime(+this.startTimeArr)
      // this.endTime = this.$gbUtils.showEndTime(+this.endTimeArr)
      this.type = parseInt(this.dataSource.uintParams[0] / 10) - 1
      this.tag = parseInt(this.dataSource.uintParams[1] / 10) - 1
      this.isReward = this.dataSource.uintParams[2] > 0
      this.timeStatus = this.dataSource.timeStatus
    }
  },
  mounted () {
    this.getTokenInfo()
    this.init()
  }
}
</script>

<style scoped lang="scss">
.proposal-wrap {
  background: $bg-color1;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 15px;
  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dao-info {
      display: flex;
      align-items: center;
      img {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        object-fit: contain;
      }
      .name {
        margin-left: 10px;
        color: $color-gray;
      }
      .time {
        margin-left: 10px;
        color: $color-orange;
      }
    }
    .time-info {
      display: flex;
      align-items: center;
      .status-wrap {
        display: flex;
        align-items: center;
      }
      .status {
        display: inline-block;
        padding: 0 10px;
        line-height: 25px;
        height: 25px;
        background: $color-blue1;
        border-radius: 12px;
        margin-right: 10px;
      }
      .status1 {
        background-color: #d0ad8a;
      }
      .status2 {
        background-color: #888888;
      }
      .status4 {
        background-color: #d0ad8a;
      }
      .time {
        color: #ff4c4c;
      }
    }
    .address {
      color: $color-gray;
    }
  }
  .title {
    padding: 15px 0;
    font-weight: 600;
    color: $base-color1;
    font-size: 14px;
    line-height: 1.5em;
  }
  .content {
    color: $base-color1;
    line-height: 17px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    .tag-group {
      display: flex;
      .tag {
        margin-right: 5px;
        font-size: 9px;
        font-weight: 400;
        color: #888888;
        padding: 3px 5px;
        background: #4a4e66;
        border-radius: 4px;
        // &.pink-bg {
        //   background: rgba($color: #ebb6c4, $alpha: 0.3);
        // }
        // &.tag0 {
        //   background: rgba($color: #ebd2b6, $alpha: 0.3);
        // }
        // &.tag1 {
        //   background: rgba($color: #ebb7b6, $alpha: 0.3);
        // }
        // &.tag2 {
        //   background: rgba($color: #b7b6eb, $alpha: 0.3);
        // }
      }
    }
    .info {
      color: $color-gray;
    }
  }
}
</style>
