<template>
  <div class="my-wrap">
    <MyHeader v-model="currentTab" @changeTab="handleTabChange" @changeMenu="handleMenuChange"></MyHeader>
    <div class="vote-list" v-if="currentTab === 0 && voteListShow">
      <VoteItem v-for="(item, index) in voteList" :key="index" :dataSource="item"></VoteItem>
      <Empty v-if="voteList <= 0" />
    </div>
    <div class="dao-list" v-else>
      <DaoItem @hide="handleHideMenu" v-for="(item, index) in myDaoList" :key="item.daoAt" :ref="`menu${index}`"
        :dataSource="item" @changeLoading="changeLoading"></DaoItem>
      <Empty v-if="myDaoList <= 0" />
    </div>
  </div>
</template>

<script>
import { getVoteStatus } from "@/utils/article";
import ContractDao from '@/contracts/contractDao'
import MyHeader from './components/MyHeader.vue'
import VoteItem from './components/VoteItem.vue'
import DaoItem from './components/DaoItem.vue'
import Empty from '../../components/common/Empty.vue'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  name: '',
  data() {
    return {
      currentTab: 0,
      daoList: [],
      myDaoList: [],
      recordResloveList: [],
      myVoteMapList: [],
      myVoteList: [], // 所有列表
      votingList: [], // 进行中列表
      endList: [], // 已结束列表
      redeemList: [], // 待赎回列表
      voteList: [], // 显示列表
      voteListShow: true,
      cobyEndList:[],
    }
  },
  methods: {
    changeLoading(flag) {
      this.$emit('changeLoadingIconType', 0)
      this.$emit('changeLoading', flag)
    },
    handleHideMenu() {
      this.daoList.forEach((item, index) => {
        if (this.$refs[`menu${index}`]) {
          this.$refs[`menu${index}`][0].menuShow = false
        }
      })
    },
    handleTabChange(val) {
      this.currentTab = val
    },
    async getDaoList() {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.daoList()
      if (!resp.success) {
        return this.$emit('changeLoading', false)
      }
      this.daoList = resp.result || []
      if (this.daoList.length <= 0) {
        return this.$emit('changeLoading', false)
      }
      this.getUserList()
    },
    async getUserList() {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      const resp = await ContractDao.userDaoList()
      if (!resp.success) {
        return this.$emit('changeLoading', false)
      }
      resp.result.forEach((item, index) => {
        if (item) {
          const obj = { ...this.daoList[index] }
          obj.name = this.lang === 'ZH' ? obj.params[1] : obj.params[2]
          obj.daoAt = index
          obj.proposalList = []
          this.myDaoList.push(obj)
        }
      })
      for (let index = 0; index < this.myDaoList.length; index++) {
        await this.handleProposalList(this.myDaoList[index].daoAt, index)
      }
      this.myVoteMapList.forEach(item => {
        const nowTime = new Date().getTime() / 1000
        let timeStatus = -1
        if (nowTime < +this.myDaoList[item.daoAt].proposalList[item.proposalAt].uintParams[3]) {
          timeStatus = 0 // 未开始
        } else if (nowTime < +this.myDaoList[item.daoAt].proposalList[item.proposalAt].uintParams[4]) {
          timeStatus = 1 // 进行中
          this.votingList.push({
            ...this.myDaoList[item.daoAt].proposalList[item.proposalAt],
            endTime: this.$gbUtils.showStartime(+this.myDaoList[item.daoAt].proposalList[item.proposalAt].uintParams[4], this.lang),
            proposalAt: item.proposalAt,
            daoAt: item.daoAt,
            logo: this.myDaoList[item.daoAt].params[0],
            name: this.lang === 'ZH' ? this.myDaoList[item.daoAt].params[1] : this.myDaoList[item.daoAt].params[2],
            token: this.myDaoList[item.daoAt].token,
            timeStatus: timeStatus
          })
        } else {
          timeStatus = 2 // 已结束
          this.endList.push({
            ...this.myDaoList[item.daoAt].proposalList[item.proposalAt],
            proposalAt: item.proposalAt,
            daoAt: item.daoAt,
            logo: this.myDaoList[item.daoAt].params[0],
            name: this.lang === 'ZH' ? this.myDaoList[item.daoAt].params[1] : this.myDaoList[item.daoAt].params[2],
            token: this.myDaoList[item.daoAt].token,
            timeStatus: 2
          })
        }
      })
      await this.getRedeemList()
      this.addList()
    },
    async addList() {
      this.myVoteList = []
      this.myVoteList.push(...this.votingList)
      this.myVoteList.push(...this.redeemList)
      this.myVoteList.push(...this.endList)
       this.voteList = this.myVoteList
      this.voteListShow = true
      this.$emit('changeLoading', false)
    },
    async handleProposalList(daoAt, myDaoindex) {
      const resp = await ContractDao.prosalList(daoAt)
      resp.result.forEach((item, index) => {
        const obj = { ...item }
        obj.index = index
        this.myDaoList[myDaoindex].proposalList.push(obj)
      })
    },
    async handleMyVoteRecord() {
      const resp = await ContractDao.userVoteRecordQuery()
      if (!resp.success) return
      for (let index = 0; index < resp.result.length; index++) {
        await this.handleResolveVoteRecord(resp.result[index])
      }
      this.myVoteMapList = this.$gbUtils.objHeavy(this.recordResloveList)
    },
    async handleResolveVoteRecord(concat) {
      const resp = await ContractDao.voteRecordResolve(concat)
      if (resp.success) {
        this.recordResloveList.push({
          daoAt: resp.result.daoAt,
          proposalAt: resp.result.proposalAt
        })
      }
    },
    async getRedeemList() {
      if (this.endList.length === 0) return
      this.cobyEndList = this.$gbUtils.objHeavy(this.endList)
      for (let index = 0; index < this.cobyEndList.length; index++) {
        await getVoteStatus({ creator: store.state.myAccount, daoId: this.cobyEndList[index].daoAt, proposal: this.cobyEndList[index].daoAt + '_' + this.cobyEndList[index].proposalAt }).then(res => {
          const status = res.data.userProposalRecordEntities[0]? res.data.userProposalRecordEntities[0].redeemStatus:true;
          if (status) {
            this.redeemList.push({
              ...this.cobyEndList[index],
              timeStatus: 4
            })
            this.endList.splice(0, 1)
            
            
          }
          
        })

      }
    },
    async getVoteRecords(daoAt, proposalAt) {
      getVoteStatus({ creator: store.state.myAccount, daoId: daoAt, proposal: daoAt + '_' + proposalAt }).then(res => {
        return res.data.userProposalRecordEntities[0]?res.data.userProposalRecordEntities[0].redeemStatus:true;
      })
      // const resp = await ContractDao.voteRecord(
      //   daoAt, proposalAt, 1000
      // )
      // return resp.result[0].status
    },
    handleMenuChange(value) { // 0全部 1投票中 2待赎回 3已结束
      switch (value) {
        case 0:
          this.voteList = this.myVoteList
          break
        case 1:
          this.voteList = this.votingList
          break
        case 2:
          this.voteList = this.redeemList
          break
        case 3:
          this.voteList = this.endList
          break
      }
      this.reload()
    },
    // 刷新
    reload() {
      this.voteListShow = false
      this.$nextTick(() => {
        this.voteListShow = true
      })
    },
    async init() {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      await this.handleMyVoteRecord()
      await this.getDaoList()
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  watch: {
  },
  mounted() {
    this.voteListShow = false
    this.init()

  },
  components: { MyHeader, VoteItem, DaoItem, Empty }
}
</script>

<style scoped lang="scss">
.my-wrap {
  padding: 15px;
  font-size: $font-12;
}
</style>
