<template>
     <div class="top">
      <!-- <div class="iconfont tongzhi"></div> -->
      <img class="tongzhi" src="../assets/images/notice.png">
      <div class="notic-content">
        <div v-html="applyRule" v-if="type === '1'"></div>
        <div v-html="rule" v-else></div>
      </div>
    </div>
</template>

<script>
export default {
  name: '',
  // type === ‘1‘ 的时候是 理事
  props: ['type'],
  data () {
    return {
      point: 0.000001
    }
  },
  computed: {
    rule () {
      return this.$t('createDao.rule', { point: this.$gbUtils.accMul(this.point, 100) })
    },
    applyRule () {
      return this.$t('my.rule', { point: this.$gbUtils.accMul(this.point, 100) })
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
 .top {
    display: flex;
    align-items: center;
    color: $color-gray3;
    .tongzhi {
      color: $color_blue1;
      width: $font-20;
      margin-right: 15px;
    }
    .notic-content {
      padding: 13px 15px;
      background: $main-bg;
      box-shadow: $shadow;
      border-radius: 10px;
      font-size: $font-12;
      position: relative;

      div {
        line-height: 1.2em;
        text-align: justify;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-right-color: $main-bg;
        left: -20px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
</style>
<style lang="scss">
.important {
  color: $color-orange !important;
  font-size: $font-12;
}
</style>
