<template>
  <VanPopup
    style="
      box-sizing: border-box;
      width: 279px;
      max-width: 350px;
      padding: 20px;
      background: #FFFFFF33;
    "
    round
    v-model="show"
    @click-overlay="closePop"
  >
    <div class="logo">
      <img :src="dataSource.params[0]" alt="" />
      <div class="name" v-if="isRemove === 0">{{ $t('my.removeTitle') }}</div>
      <div class="name" v-else>{{ $t('my.quitTitle') }}</div>
    </div>
    <div class="des" v-html="rule" v-if="isRemove === 0"></div>
    <div class="des des1" v-html="rule1" v-else></div>
    <div class="btn-group">
      <VanButton class="button" :loading="btnLoading" @click="handleDao">{{
        $t('common.confirm')
      }}</VanButton>
    </div>
  </VanPopup>
</template>

<script>
import contractDao from '@/contracts/contractDao'
import { mapState } from 'vuex'
import ContractToken from '@/contracts/contractToken'

export default {
  name: '',
  props: ['isRemove', 'dataSource'],
  data () {
    return {
      show: true,
      daoName: '我的DAO',
      isQuit: 0,
      btnLoading: false,
      tokenName: '',
      totalSupply: 0
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async handleDao () {
      this.btnLoading = true
      console.log('this.isQuit', this.isQuit)
      if (!this.isQuit) {
        console.log('councliQuit')
        const resp = await contractDao.councliQuit(this.dataSource.daoAt)
        this.btnLoading = false
        if (!resp.success) return
        this.$toast('success')
        this.$parent.$parent.reload()
        this.closePop()
      } else {
        console.log('Daoquit')
        const resp = await contractDao.daoQuit(this.dataSource.daoAt)
        this.btnLoading = false
        if (!resp.success) return
        this.$toast('success')
        this.$parent.$parent.reload()
        this.closePop()
      }
    },
    async getTokenInfo () {
      const nameRes = await ContractToken.getSymbol(this.dataSource.token)
      const supplyRes = await ContractToken.totalSupply(this.dataSource.token)
      if (nameRes.success && supplyRes.success) {
        this.tokenName = nameRes.result
        this.totalSupply = supplyRes.result
      }
    }
  },
  computed: {
    ...mapState(['lang']),
    rule () {
      return this.$t('my.removeDao', { daoName: this.lang === 'ZH' ? this.dataSource.params[1] : this.dataSource.params[2], stakeNumber: this.totalSupply / 100, tokenName: this.tokenName })
    },
    rule1 () {
      return this.$t('my.quitDao', { daoName: this.lang === 'ZH' ? this.dataSource.params[1] : this.dataSource.params[2] })
    }
  },
  watch: {
    isRemove (val) {
      this.isQuit = val
      console.log('this.isQuit', this.isQuit)
    }
  },
  mounted () {
    this.getTokenInfo()
    this.isQuit = this.isRemove
    console.log('this.isQuit', this.isQuit)
  }
}
</script>

<style scoped lang="scss">
.logo {
  text-align: center;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .name {
    margin-top: 20px;
    font-weight: 600;
    color: #F9F9F9;
    font-size: 20px;
  }
}
.des {
  margin: 27px 0;
  color: #F9F9F9;
  word-break: break-all;
  line-height: 1.5em;
  font-size: 14px;
}
.des1 {
  text-align: center;
}
.red {
  color: $color-orange !important;
}
.btn-group {
  display: flex;
  justify-content: center;
}
.button {
  width: 100%;
  height: 39px;
  padding: 0 50px;
  background: $blue-bg;
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 39px;
}
</style>
